// // rem等比适配配置文件
// // 基准大小
// const baseSize = 20;
// // 设置 rem 函数
// function setRem() {
//   // 当前页面宽度相对于1920宽的缩放比例，可根据自己需要修改。
//   const scale = document.documentElement.clientWidth / 1920;
//   // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
//   const fontsize = baseSize * Math.min(scale, 4);
//   document.documentElement.style.fontSize = `${
//     fontsize >= 12 ? fontsize : 12
//   }px`;
// }
// // 初始化
// setRem();
// // 改变窗口大小时重新设置 rem
// window.onresize = function () {
//   setRem();
// };

const docEl = document.documentElement;
const dpr = window.devicePixelRatio || 1;

const setBodyFontSize = () => {
  if (document.body) {
    document.body.style.fontSize = 12 * dpr + "px";
  } else {
    document.addEventListener("DOMContentLoaded", setBodyFontSize);
  }
};

const setRemUnit = () => {
  var rem = docEl.clientWidth / 10;
  const devicePixelRatio = window.devicePixelRatio || 1;
  docEl.style.fontSize = rem * devicePixelRatio + "px";
};

setBodyFontSize();

setRemUnit();

window.addEventListener("resize", setRemUnit);
window.addEventListener("pageshow", function (e) {
  if (e.persisted) {
    setRemUnit();
  }
});

if (dpr >= 2) {
  var fakeBody = document.createElement("body");
  var testElement = document.createElement("div");
  testElement.style.border = ".5px solid transparent";
  fakeBody.appendChild(testElement);
  docEl.appendChild(fakeBody);
  if (testElement.offsetHeight === 1) {
    docEl.classList.add("hairlines");
  }
  docEl.removeChild(fakeBody);
}
