import { createStore } from "vuex";

export default createStore({
  state: {
    currentActive: "/",
  },
  getters: {
    currentActive(state) {
      return state.currentActive;
    },
  },
  mutations: {
    setActive(state, path) {
      state.currentActive = path;
    },
  },
  actions: {},
  modules: {},
});
