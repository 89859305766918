<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="header-content">
          <img src="./assets/imgs/logo.png" style="height: 32px" />
          <el-menu
            :default-active="currentActive"
            mode="horizontal"
            :ellipsis="false"
            @select="handleSelect"
            :router="true"
            background-color="#00194d"
            text-color="#fff"
            active-text-color="#fff"
            style="border-bottom: none"
          >
            <el-menu-item index="/">首页</el-menu-item>
            <!-- <el-menu-item index="/product">好运</el-menu-item> -->
            <el-sub-menu index="product">
              <template v-slot:title>产品</template>
              <el-menu-item index="/transport">好运</el-menu-item>
              <el-menu-item index="/agreeon">好约</el-menu-item>
              <el-menu-item index="/finance">好融</el-menu-item>
              <el-menu-item index="/ilink">好运智链</el-menu-item>
              <el-menu-item index="/protect">好运护航</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/advice">
              <template v-slot:title>咨询</template>
              <el-menu-item index="/advice">资质申报</el-menu-item>
              <el-menu-item index="/industry">行业运营</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/tech">
              <template v-slot:title>技术</template>
              <el-menu-item index="/tech">增值组件</el-menu-item>
              <el-menu-item index="/system-link">系统互联</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/about">
              <template v-slot:title>关于我们</template>
              <el-menu-item index="/about">关于我们</el-menu-item>
              <el-menu-item index="/intro">公司简介</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </div>
      </el-header>
      <el-main>
        <router-view :key="routerKey" />
      </el-main>

      <el-footer style="height: auto">
        <div class="footer-content">
          <div style="display: flex; flex-direction: column">
            <span style="font-size: 20px; font-weight: 400">真好运科技</span>
            <span style="font-size: 16px; margin-top: 40px"
              >真好运科技是以网络承运为核心,
            </span>
            <span style="font-size: 16px; margin-top: 10px">
              覆盖整车运输全场景的科技服务商。</span
            >
          </div>
          <div
            style="
              display: flex;
              justify-content: space-around;
              flex-grow: 1;
              margin: 0 60px;
            "
          >
            <div>
              <span>&emsp;产品方案</span>
              <ul class="link left">
                <li class="link-a">
                  <router-link to="/transport"
                    ><a href=""><i class="link-img"></i> 好运</a></router-link
                  >
                </li>
                <li class="link-a">
                  <router-link to="/agreeon">
                    <a href=""><i class="link-img"></i>好约</a>
                  </router-link>
                </li>
                <li class="link-a">
                  <router-link to="/finance">
                    <a href=""><i class="link-img"></i>好融</a>
                  </router-link>
                </li>
                <li class="link-a">
                  <router-link to="/ilink">
                    <a href=""><i class="link-img"></i>好运智链</a>
                  </router-link>
                </li>
                <li class="link-a">
                  <router-link to="/protect">
                    <a href=""><i class="link-img"></i>好运护航</a>
                  </router-link>
                </li>
              </ul>
            </div>
            <div>
              <span>&emsp;咨询</span>
              <ul class="link">
                <li class="link-a">
                  <router-link to="/advice">
                    <a href=""><i class="link-img"></i>政策咨询</a>
                  </router-link>
                </li>
                <li class="link-a">
                  <router-link to="/industry">
                    <a href=""><i class="link-img"></i>行业运营</a>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div style="display: flex; flex-direction: column">
            <span style="font-size: 20px; font-weight: 400">联系我们</span>
            <span style="font-size: 20px; font-weight: 400; margin-top: 15px"
              >400-685-0510</span
            >
            <img
              src="@/assets/imgs/qrcode.png"
              style="width: 160px; height: 160px; margin-top: 20px"
            />
          </div>
        </div>

        <div class="beian">
          Copyright 2023-2024 无锡远迈信息技术有限公司 All Rights Reserved
          <a
            target="_blank"
            style="color: #ccc; text-decoration: none"
            href="https://beian.miit.gov.cn"
            >苏ICP备14054678号-1</a
          >
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";

const store = useStore();
const currentActive = computed(() => store.state.currentActive);
const route = useRoute();

const handleSelect = (key) => {
  store.commit("setActive", key);
};

const routerKey = computed(() => {
  return route.path + Math.random();
});

const isMobile = () => {
  let flag =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  return flag;
};

onMounted(() => {
  if (isMobile()) {
    ElMessageBox.confirm(
      "移动版正在建设中，请前往电脑端浏览真好运科技官网",
      "提示",
      {
        showConfirmButton: false,
        showCancelButton: false,
        type: "warning",
      }
    )
      .then(() => {})
      .catch(() => {});
  }
});
</script>

<style lang="scss" scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1240px;
  margin: 0 auto;
  //background-color: #fff;

  .logo {
    width: 280px;
    padding: 10px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fff;
    img {
      width: 100%;
      line-height: 60px;
    }
  }
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 1240px;
  margin: 0 auto;
  padding: 60px 0 30px 0;
  color: #fff;
}

.link {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 25px;
  .link-a {
    list-style: none;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 48px;
      margin-left: 10px;
      .link-img {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("@/assets/imgs/link.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}
.left {
  height: 180px;
  .link-a {
    margin-right: 60px;
  }
}
.beian {
  color: #fff;
  font-size: 16px;
  margin-bottom: 50px;
  text-align: center;
}
</style>
